import React from 'react';
import './pears.css';
import pear from "./img2.jpg";  // Make sure to have a pear image in the same directory

function Pears() {
    return (
        <div className="pear-container">
            <h3>Pears</h3>
            <p>The Pacific Northwest is responsible for the majority of the fresh pears grown in the United Sates. The cold winters, wet springs, and dry summers provide a perfect climate for growing and ripening a wide variety of pears. Pears are a powerhouse fruit, packing fiber, vitamins, and beneficial nutrients. These nutrients are thought to fight inflammation, promote gut and heart health, protect against certain diseases, and even aid weight loss. </p>
            <h2>
                <ul className="pear-name-list">
                    <li>Pear Varieties</li>
                </ul>
            </h2>
            <img src={pear} alt="Pear varieties" className="pear-image" />
            <ul className="pear-list">
                <li>➢ Bosc</li>
                <li>➢ Anjou</li>
                <li>➢ Bartlett</li>
                <li>➢ Comice</li>
                <li>➢ Concorde</li>
                <li>➢ Seckel</li>
            </ul>
        </div>
    );
}

export default Pears;
