import React from 'react';
import './LandingPage.css';
import Carousel from 'react-bootstrap/Carousel';
import Img from "./img.jpg";
import Img1 from "./img1.jpg";
import Img2 from "./img2.jpg";
import Img3 from "./img3.jpg";
import Img4 from "./img4.jpg";

function LandingPage() {
    return (
        <div className="landing-container">
            <div className="title">
              <h2> Welcome to Ganesha Traders </h2>
                <h3>The Trading Partner For Fresh Fruit And Nuts.</h3>
                <h5>Located in California, Ganesha Traders is redefining the way premium fresh produce is globally distributed. Our dedication to supplying top-of-the-line fresh  products as well as our long line of strong partnerships and customers, have helped us reach the finest of qualities which we are proud to present to communities in different part of the world.</h5>
                <h5>We envision a nation with an enhanced livelihood that can be achieved through fair and ethical growing and trade in fresh produce. We intend to expand our services globally in order to contribute to a healthy food environment.</h5>
            </div>
      <Carousel>
        <Carousel.Item interval={1000}>
          <img className="d-block w-100 carousel-img" src={Img} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img className="d-block w-100 carousel-img" src={Img1} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 carousel-img" src={Img2} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 carousel-img" src={Img3} alt="Fourth slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 carousel-img" src={Img4} alt="Fifth slide" />
        </Carousel.Item>
      </Carousel>
      </div>
      
    );
}

export default LandingPage;
