import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <div className="about-container">
            <h2>About Us</h2>
            
            <p> We are a renowned and growing company that supplies fresh produce from United States, Turkey and Australia. We are a group of energetic team members located in the United States. We supply premium quality fruits and nuts at very good price with extremely high quality. Our customer base established in various countries and they value our service quality, timeliness, professionalism and integrity.  Continuous improvement is part of our ongoing service level assessment and value offering refinement process. </p>
            <p> We work with farmers, growers and packers and ship it to various countries in the globe. We partner with some of the best growers and packers in the produce industry and have an elite team of international sales representatives that work round the clock to provide our clients with the best experience on fruit seasons, availability status, crop forecast, harvest and quality updates. We have multiple levels of quality inspection that ensure the supply of best quality products to our customers. Please contact us for any produce need and and learn more about us and our commitment to our customers. </p>
           
        
        </div>
    );
}

export default AboutUs;
