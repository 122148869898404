import React from 'react';
import './Mission.css';

function Mission() {
    return (
        <div className="mission-container">
            <h2> Mission </h2>
            <h5>Passionate about providing fresh fruits and nuts</h5>
            <p>➢ At Ganesha Traders people work with a passion for fresh foods. The team live and carry our philosophy ㅤof quality and sustainability. They contribute lots of expertise and ideas to further develop our mission ㅤㅤand they are on hand with help and advice for food retailers and any interested parties.</p>
            <p>➢ Our aim is to process fresh, controlled products, which we bring to retailers by the shortest possibleㅤㅤㅤ route</p>
            <p>➢ Sophisticated logistics processes ensure that transport times are kept as short as possible.</p>
            <p>➢ Demand-actuated complete and partial deliveries offer maximum flexibility for individual requirements.</p>
          
        </div>
    );
}

export default Mission;
