import { Container, Row, Col } from "react-bootstrap";
import './footer.css';
const Footer = () => {
    

    return (
        <footer style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#000779' }}>
            <Container style={{ width: '100%' }}>
    <Row>
       
            <Col className="custom-footer">
    <p> Suppliers of Premium Quality Produce </p>
           </Col>

        
    </Row>
</Container>

        </footer>
    )
}

export default Footer;
