import './header.css';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Ensure you've imported Link for routing
import logo from './logo.png';

function Header() {
    return (
        <div className="header">
    <div className="logo-section">
        <Link to="/"> {/* This Link wraps around the logo */}
            <img src={logo} alt="Ganesha Traders Logo" className="logo-image" />
        </Link>
        <span className="logo-text"> GANESHA TRADERS </span>
    </div>
            <nav>
                <ul>
                    <li>
                        <NavDropdown title="Our Company" id="company-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/aboutus">About Us</NavDropdown.Item> {/* Updated link here */}
                            <NavDropdown.Item as={Link} to="/qualitymanagment">Quality Managment </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/mission"> Mission </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/products">Products </NavDropdown.Item>
                        </NavDropdown>
                    </li>

                    <li>
                        <NavDropdown title="Product" id="product-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/apples">Apples</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/pears">Pears</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/almonds">Almonds</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/pistachios">Pistachios</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/avocado">Avocado</NavDropdown.Item>
                        </NavDropdown>
                    </li>
                    <li><Link to="/contactus">Contact Us</Link></li>
                </ul>
            </nav>
        </div>
    );
}

export default Header;