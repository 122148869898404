import React from 'react';
import './apples.css';
import apple from "./img.jpg";  // Replace with your actual path

function Apples() {
    return (
        <div className="apple-container">
            <h3>Apples</h3>
            <p>Washington's East of the Cascade Mountain Range is an ideal place for growing apples. The rich soil, superior growing conditions, and abundant access to water enable us to grow world-class tree fruit. Apples come in a variety of shapes, colors, and flavors and provide a range of nutrients that may help with many health issues, including lowering cholesterol, heart disease and stroke prevention, and potentially reducing the risk of cancer and diabetes.</p>
            <h2>
                <ul className="apple-name-list">
                    <li>Apples Varieties</li>
                </ul>
            </h2>
            <img src={apple} alt="Apple varieties" className="apple-image" />
            <ul className="apple-list">
                <li>➢ Red Delicious</li>
                <li>➢ Pink Lady</li>
                <li>➢ Fuji</li>
                <li>➢ Gala</li>
                <li>➢ Granny Smith</li>
                <li>➢ Golden Delicious</li>
            </ul>
        </div>
    );
}

export default Apples;
