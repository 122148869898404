import React from 'react';
import "./ContactUs.css"
function ContactUs() {
    return (
        <div className="contact-container">
            <h2 className="about-title">Contact Us</h2>
            
            <div className="static-info">
            
                <h3><b>Ganesha Traders are here to help you.</b></h3> 
                <h6><b>Would you like to get to know Ganesha Traders better? Simply get in contact with us,<br></br> by e-mail or by telephone.</b> </h6>
                <h6><b> We look forward to talking with you and will be happy to answer your questions.</b> </h6> 
                    
                <br></br>
                <h3>USA Office</h3>
                
                <p><strong> Ganesha Traders </strong></p>
                <p><strong>Yorba linda, CA 92887 </strong></p>
                <p><strong>support@ganeshatradersusa.com </strong></p>
                <p><strong>www.ganeshatradersusa.com</strong></p>
                <p><strong>Phone :- +1(949)-350-6853 </strong></p>
                
            </div>
        </div>
    );
}

export default ContactUs;
