import React from 'react';
import './avocado.css';
import avocadoImg from "./img1.jpg";  // Make sure you have an avocado image in the same directory

function Avocado() {
    return (
        <div className="avocado-container">
            <h3> Avocado</h3>
            <p>We source a number of varieties of Avocados to meet year-round demand for our premium fruit. Most of our varieties are available in both organic and conventional growing methods. Avocados are a source of key nutrients, including healthy fats and fiber. They also contain anti-inflammatory and antioxidant compounds and may help reduce heart disease risk. Nutritious, versatile, and delicious, avocados have become a kitchen staple in many homes around the world</p>
            <h2>
                <ul className="avocado-name-list">
                    <li>Avocado Varieties</li>
                </ul>
            </h2>
            <img src={avocadoImg} alt="Avocado varieties" className="avocado-image" />
            <ul className="avocado-list">
                <li>➢ Hass</li>
                <li>➢ Fuerte</li>
                <li>➢ Pinkerton</li>
                <li>➢ Gwen</li>
                <li>➢ Bacon</li>
                <li>➢ Zutano</li>
            </ul>
        </div>
    );
}

export default Avocado;
