import React from 'react';
import './QualityManagment.css';

function QualityManagment() {
    return (
        <div className="about-container">
            <h2> Quality Managment </h2>
            <h5>Quality right from the start.</h5>
            
            <p>➢ In order to meet the high quality requirements of food retailers, the uncompromising quality assurance of our products throughout the whole supply chain is our top priority. This is the target that we achieve every day.</p>
            
            <p>➢ Our aim is to ensure product safety and quality, in cooperation with our long-time and certified suppliers. For this purpose, we count on comprehensive residue monitoring, extensive internal and external quality controls, and competent advising of our suppliers regarding crop protection and cultivation methods.</p>
          
            <p>➢ In order to guarantee all of this, the consultants and agricultural engineers of our quality assurance cooperate closely with independent food laboratories. In addition to this, we ensure the documented traceability of each individual batch to the field. And we are constantly fine-tuning targeted measures for process optimisation, together with the growers.</p>
            
        </div>
    );
}

export default QualityManagment;
