import React from 'react';
import './products.css';

function Products() {
    return (
        <div className="product-container">
            <h2>Product's</h2>
            
            <p>Our objective is the year-round supplying of the food to the retail industry. This is why Ganesha Traders procures its products from selected growers from different continents.</p>
            <p>Comprehensive, transparently documented quality assurance through the whole supply chain ensures maximum freshness and product safety, which you can see and taste.</p>
            
            <h3>Fruit and Nuts</h3>
            <p>The range of fresh fruit and nuts comprises a wide variety of apples, berries, exotic fruits, almonds, pistachio and others.</p>
        </div>
    );
}

export default Products;
