import React from 'react';
import './pistachios.css';
import pistachio from "./img3.jpg";  // Ensure you have a pistachio image in the same directory

function Pistachios() {
    return (
        <div className="pistachio-container">
            <h3> Pistachios </h3>
            <p>California pistachios are a nutrient-dense food with heart- healthy fats as well as protein, dietary fiber, potassium, magnesium and anti-oxidants such as gamma tocopherol and phytochemicals lutein and anthocyanin that give the unique green and purple kernel color.</p>
            <h2>
                <ul className="pistachio-name-list">
                    <li>Pistachio Varieties</li>
                </ul>
            </h2>
            <img src={pistachio} alt="Pistachio varieties" className="pistachio-image" />
            <ul className="pistachio-list">
                <li>➢ Kerman</li>
                <li>➢ Golden Hills</li>
                <li>➢ Lost Hills</li>
                <li>➢ Kalehghouchi</li>
                <li>➢ Aegina</li>
                <li>➢ Siirt</li>
            </ul>
        </div>
    );
}

export default Pistachios;
