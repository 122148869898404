import React from 'react';
import './almonds.css';
import almond from "./img4.jpg";  // Ensure you have an almond image in the same directory

function Almonds() {
    return (
        <div className="almond-container">
            <h3>Almonds </h3>
            <p> California almonds are renowned throughout the world for their incomparable flavor work. They not only have a crunchy and delicious taste, but they have also been lauded as one of the healthiest foods we can consume. They are a great source of fiber, protein and health fats while also being rich in antioxidants. They can help lower bad cholesterol and prevent insulin spikes</p>
            <h2>
                <ul className="almond-name-list">
                    <li>Almond Varieties</li>
                </ul>
            </h2>
            <img src={almond} alt="Almond varieties" className="almond-image" />
            <ul className="almond-list">
                <li>➢ Carmel</li>
                <li>➢ Nonpareil</li>
                <li>➢ Butte & Padre</li>
                <li>➢ Tuono</li>
                <li>➢ Marcona</li>
                <li>➢ Mamra</li>
            </ul>
        </div>
    );
}

export default Almonds;
