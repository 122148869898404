import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// CSS

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// component

import Header from "./component/Header/header";
import LandingPage from './component/Screens/LandingPage';
import Footer from "./component/Footer/footer";
import ContactUs from './component/Header/ContactUs/ContactUs';
import AboutUs from './component/Header/OurCompany/AboutUs';  
import QualityManagment from './component/Header/OurCompany/QualityManagment'; 
import Mission from './component/Header/OurCompany/Mission';
import Products from './component/Header/OurCompany/products';

// Product's

import Apples from './component/Header/Product/apples';
import Pears from './component/Header/Product/pears';
import Almonds from './component/Header/Product/almonds';
import Pistachios from './component/Header/Product/pistachios';
import Avocado from './component/Header/Product/avocado';

function App() {
    return (
        <Router>
            <div id="app">
                <Header /> <br></br>
                <div className="content">
                    <Switch>
                        <Route path="/" exact component={LandingPage} />
                        <Route path="/contactus" component={ContactUs} />
                        <Route path="/aboutus" component={AboutUs} />  
                        <Route path="/qualitymanagment" component={QualityManagment} />
                        <Route path="/mission" component={Mission} /> 
                        <Route path="/apples" component={Apples} /> 
                        <Route path="/pears" component={Pears} /> 
                        <Route path="/almonds" component={Almonds} /> 
                        <Route path="/pistachios" component={Pistachios} /> 
                        <Route path="/avocado" component={Avocado} /> 
                        <Route path="/products" component={Products} /> 
                    </Switch>
                </div>
                <br></br>
                <Footer />  
            </div>
        </Router>
    );
}

export default App;
